import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useRouter } from 'next/router';
import { urlPath } from 'utils/redirects';
import { AxiosError } from 'axios';
import {
  apiUrl,
  appUrl,
  PlushcareWebAPI,
  todayInTimezone,
} from 'src/js/utils';
import Analytics from 'src/js/utils/analytics/Analytics';
import { clearPreBookedAppointment } from 'src/js/actions/BookingActions';
import CookieStorage from 'src/js/utils/CookieStorage';
import { calcBookingHoldLeftTime } from 'src/js/components/Stepper/Timer/Timer';
import { preBookedAppointment } from 'src/js/store/selectors';
import { State as BookingState } from 'src/js/reducers/BookingReducer';
import { HOLD_APT_TIME_COOKIE_NAME } from 'src/js/nextgen/utils/constants';
import { BookingPricingOption } from 'src/js/endpoints/therapy';

export function shouldNavigateToRecurringOptions(pricing: BookingPricingOption[] = []) {
  return pricing.filter(plan => plan.available).length > 1;
}

// TODO integrate this into BookingFlowContext
const routesForPrompt = new Set([
  appUrl.booking.register.pc,
  appUrl.booking.therapy.payment,
  '/profile/confirm/',
  appUrl.booking.payment.pc,
  '/profile/payment/',
]);

const routesForRemovingPreBookedApptmnt = new Set([
  appUrl.booking.method,
  appUrl.booking.appointments,
  appUrl.booking.selectPharmacy,
]);

const NextRouterChangeWatcher = () => {
  const heldAppointment = useSelector(preBookedAppointment);
  const router = useRouter();

  const routerCurrentPath = router.asPath;

  useEffect(() => {
    const handleRouteChange = async (url: string) => {
      const isPageRefresh = urlPath(routerCurrentPath) === urlPath(url);
      if (!isPageRefresh && heldAppointment && routesForRemovingPreBookedApptmnt.has(urlPath(url))) {
        const eventProperties = {
          url,
          hold_release_reason: 'redirection',
          hold_id: heldAppointment.id,
          hold_start_time: getAppointmentHoldStartTime(),
        };
        Analytics.track('Web - Release Appointment', eventProperties);
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heldAppointment]);

  return null;
};

export function PreBookedAppointmentWatcher() {
  return (
    <>
      <Component />
      <NextRouterChangeWatcher />
    </>
  );
}

// used to separate parent with history listener
// from re-renders connected with update of Booking Store when user hits "Book" CTA
export const Component = () => {
  const appointment = useSelector(preBookedAppointment);
  const dispatch = useDispatch();

  const next_router = useRouter();
  const url = next_router.asPath;

  // sets up tracking of remaining booked time
  // used for 'releasing' appointment from booking when time is up
  useEffect(() => {
    if (!appointment) return;
    const appDuration = appointment?.hold_length ?? 0;

    const timerId = setInterval(() => {
      const timeLeft = calcBookingHoldLeftTime(appDuration, getAppointmentHoldStartTime(), todayInTimezone());
      if (timeLeft <= 0) {
        const eventProperties = {
          url,
          hold_release_reason: 'timer',
          hold_id: appointment.id,
          hold_start_time: getAppointmentHoldStartTime(),
        };
        Analytics.track('Web - Release Appointment', eventProperties);
        releaseAppointmentFromPreBooking({ appointment_id: appointment.id }, dispatch);
      }
    }, 5000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  useDynamicRoutingHandler(appointment);
  return null;
};

export async function releaseAppointmentFromPreBooking(
  { appointment_id }: { appointment_id: number },
  dispatch: Dispatch | Function = () => {},
) {
  const result: {
    data: any; // atm endpoint returns empty response
    error?: string | Error;
    status?: number;
  } = {
    data: null,
  };

  if (!appointment_id) {
    result.error = 'no appointment_id';
    return Promise.reject(result);
  }

  dispatch(clearPreBookedAppointment());

  try {
    const { data, status } = await PlushcareWebAPI.apiPost(apiUrl.patients.appointmentReleaseHold, { appointment_id });
    result.data = data;
    result.status = status;
  } catch (e) {
    const error = e as AxiosError;
    result.error = error;
    result.status = error?.response?.status;
  }

  return result;
}

function getAppointmentHoldStartTime() {
  return CookieStorage.get(HOLD_APT_TIME_COOKIE_NAME);
}

export function useDynamicRoutingHandler(app: BookingState['preBookedAppointment']) {
  // TODO: booking flows have ot be separated, otherwise such logic as below will grow with time
  useEffect(() => {
    if (app?.pricing && shouldNavigateToRecurringOptions(app.pricing)) {
      routesForPrompt.delete(appUrl.booking.therapy.register);
    } else {
      routesForPrompt.add(appUrl.booking.therapy.register);
    }
  }, [app]);

  return routesForPrompt;
}
