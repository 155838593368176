import { ParsedUrlQueryInput } from 'querystring';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useIsEnterpriseCoverageUser,
  useIsPartialEnterpriseUser,
  useUserData,
} from 'src/js/endpoints/users';
import {
  addToDate, diffInDays,
} from 'src/js/utils/datetime';
import {
  hasFitbitStudy, hasToConfirmProfile, isLoggedIn,
  isUserEligible, getFlowType,
} from 'src/js/store/selectors';
import { appUrl, LocalStorage } from 'src/js/utils';
import { rescheduleAppointmentId } from 'src/js/utils/location';
import { setFlowType, setInitialBookingDataLoaded } from 'src/js/actions/BookingActions';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import { useFeatureFlag } from 'src/js/abTesting/hooks';
import { FLAGS } from 'src/js/abTesting/flags';
import { Employee } from 'src/js/reducers/UserReducer';
import { FLOW_TYPES } from 'src/js/nextgen/utils/constants';
import { useCoverage, useIsTPAUser } from 'src/js/endpoints/insurance';

type Step = {
  name: string,
  url: string,
}
// this below is only to not do :Step
const step = (s: Step) => s;

const allSteps = {
  howToSchedule: step({
    name: 'howToSchedule',
    url: appUrl.booking.specialist.howToSchedule,
  }),
  specialistRequestForm: step({
    name: 'specialistRequestForm',
    url: appUrl.booking.specialist.specialistRequestForm,
  }),
  specialistSearch: step({
    name: 'specialistSearch',
    url: appUrl.booking.specialist.clinicianSearch,
  }),
  preventativeAnnualEligible: step({
    name: 'preventativeAnnualEligible',
    url: appUrl.booking.preventative.annual.checkEligibility,
  }),
  method: step({
    name: 'method',
    url: appUrl.booking.method,
  }),
  insurance: step({
    name: 'insurance',
    url: appUrl.booking.insurance,
  }),
  selectState: step({
    name: 'state',
    url: appUrl.booking.selectState,
  }),
  clinicianSearch: step({
    name: 'clinicianSearch',
    url: appUrl.booking.clinicianFirst.clinicianSearch,
  }),
  clinicianSearchListMidFlow: step({
    name: 'clinicianSearchListMidFlow',
    url: appUrl.booking.clinicianFirst.clinicianSearchListMidFlow,
  }),
  clinicianSearchPCP: step({
    name: 'clinicianSearchPCP',
    url: appUrl.booking.clinicianFirst.clinicianSearchPCP,
  }),
  clinicianChoicePCP: step({
    name: 'clinicianChoicePCP',
    url: appUrl.booking.clinicianFirst.clinicianChoicePCP,
  }),
  clinicianSchedule: step({
    name: 'clinicianSchedule',
    url: appUrl.booking.clinicianFirst.schedule,
  }),
  selectAppointment: step({
    name: 'appointments',
    url: appUrl.booking.appointments,
  }),
  appointmentsByDoctor: step({
    name: 'appointmentsByDoctor',
    url: appUrl.booking.appointmentsByDoctor,
  }),
  payment: step({
    name: 'payment',
    url: appUrl.booking.payment.pc,
  }),
  enterpriseVerification: step({
    name: 'enterpriseVerification',
    url: appUrl.enterprise.verify,
  }),
  registration: step({
    name: 'registration',
    url: appUrl.booking.register.pc,
  }),
  confirmProfile: step({
    name: 'confirmProfile',
    url: appUrl.booking.confirmProfile,
  }),
  selectPatient: step({
    name: 'selectPatient',
    url: appUrl.booking.selectPatient,
  }),
  addChild: step({
    name: 'addChild',
    url: appUrl.booking.addChild,
  }),
  updateMemberId: step({
    name: 'updateMemberId',
    url: appUrl.booking.updateMemberId,
  }),
};

const {
  howToSchedule,
  specialistSearch,
  specialistRequestForm,
  preventativeAnnualEligible,
  method,
  insurance,
  selectState,
  clinicianSearch,
  clinicianSearchPCP,
  clinicianChoicePCP,
  clinicianSearchListMidFlow,
  clinicianSchedule,
  selectAppointment,
  registration,
  payment,
  confirmProfile,
  enterpriseVerification,
  selectPatient,
  addChild,
  appointmentsByDoctor,
  updateMemberId,
} = allSteps;

type PossibleStep = keyof typeof allSteps

export enum FlowType {
  // Do not start with or use zero enum
  PrimaryCare = 1,
  ClinicianFirst,
  PCPBooking,
  PCPChoice,
  Specialist,
  PreventativeAWV,
}

export const flushBookingFlow = (dispatch: any, router: any, checkRoute: boolean = true) => {
  let doFlush = checkRoute;
  if (checkRoute) {
    const all = Object.entries(allSteps);
    for (const s in all) {
      if (router.asPath.includes(all[s][1].url)) {
        doFlush = false;
        break;
      }
    }
  }

  if (doFlush) {
    dispatch(setFlowType(null));
    dispatch(setInitialBookingDataLoaded(false));
  }
};

// eslint-disable-next-line max-len
export const adjustAppointmentDate = (finalQuery: ParsedUrlQueryInput, employee: Employee | null, date_joined: string) => {
  if (employee?.is_bsc_ifp && !finalQuery.date && finalQuery.flow_sub_type === 'annual_visit') {
    const minDate = new Date();
    const dateJoined = new Date(date_joined);
    const delay = employee.risk_assessment_delay || 0;
    const diff = diffInDays(minDate, dateJoined);
    if (diff < delay) {
      return { date: addToDate('d', delay, date_joined) };
    }
  }
  return {};
};

export const usePCBookingFlow = (
  fType: FlowType | null = null,
  { prefetchNextPage }: { prefetchNextPage: boolean } = { prefetchNextPage: true },
) => {
  const router = useRouter();
  const dispatch = useDispatch();

  if (fType) {
    dispatch(setFlowType(fType));
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const flowType = fType || useSelector(getFlowType);

  const rescheduleId = rescheduleAppointmentId(router.query);
  const isReschedule = !!rescheduleId;

  const hasEnterpriseCoverage = useIsEnterpriseCoverageUser();
  const needsVerification = useIsPartialEnterpriseUser();

  const isTPA = useIsTPAUser();
  const isFitbitStudy = useSelector(hasFitbitStudy);
  const isEligible = useSelector(isUserEligible);
  const isLoggedUser = useSelector(isLoggedIn);
  const needsConfirmProfile = useSelector(hasToConfirmProfile);

  const isInsured = (isFitbitStudy || isTPA || isEligible || hasEnterpriseCoverage);
  const enableMethodChoice = !isInsured && !isReschedule;

  const { variant } = useAppVariant();
  const isAccoladeCare = variant === AppVariants.AccoladeCare;
  const { data: userData } = useUserData();
  const { data: coverage } = useCoverage();

  const [dc2ChildBookingDecision] = useFeatureFlag(FLAGS.D2C_CHILD_BOOKING_FLOW, { autoUpdate: true });

  const goToAddChildPage = () => {
    return (dc2ChildBookingDecision.enabled || isAccoladeCare) && (router.query.patient && router.query.patient === 'add-child');
  };

  const goToUpdateMemberIdPage = () => {
    if (!coverage || !hasEnterpriseCoverage || !('member_id' in coverage)) return false;
    return !coverage?.member_id;
  };

  const apptByDoctor = LocalStorage.getItem('appt_by_doctor');

  const PrimaryCareFlow = [
    enableMethodChoice ? method : null,
    enableMethodChoice ? insurance : null,
    (isAccoladeCare || (dc2ChildBookingDecision.enabled && userData.is_logged_in)) ? selectPatient : null,
    goToAddChildPage() ? addChild : null,
    selectState,
    selectAppointment,
    apptByDoctor ? appointmentsByDoctor : null,
    !isLoggedUser ? registration : null,
    needsConfirmProfile ? confirmProfile : null,
    needsVerification ? enterpriseVerification : null,
    goToUpdateMemberIdPage() ? updateMemberId : null,
    payment,
  ].filter(st => st !== null) as Step[]; // null steps removed

  const ClinicianFirstFlow = [
    clinicianSearch,
    clinicianSearchListMidFlow,
    clinicianSchedule,
    goToUpdateMemberIdPage() ? updateMemberId : null,
    payment,
  ].filter(st => st !== null) as Step[]; // null steps removed

  const PCPBookingFlow = [
    clinicianSearchPCP,
    clinicianSchedule,
    goToUpdateMemberIdPage() ? updateMemberId : null,
    payment,
  ].filter(st => st !== null) as Step[]; // null steps removed

  const PCPChoiceFlow = [
    clinicianChoicePCP,
    clinicianSearchListMidFlow,
    clinicianSchedule,
    goToUpdateMemberIdPage() ? updateMemberId : null,
    payment,
  ].filter(st => st !== null) as Step[]; // null steps removed

  const SpecialistFlow = [
    howToSchedule,
    specialistSearch,
    specialistRequestForm,
  ].filter(st => st !== null) as Step[]; // null steps removed

  // Preventative Annual Wellness Visit Flow
  const AnnualWellnessVisitFlow = [
    preventativeAnnualEligible,
    selectState,
    selectAppointment,
    goToUpdateMemberIdPage() ? updateMemberId : null,
    payment,
  ].filter(st => st !== null) as Step[];

  const loadFlow = (ft: FlowType | null): { loginRequired: boolean, flow: Step[] } => {
    switch (ft) {
      case FlowType.PrimaryCare:
        return { loginRequired: false, flow: PrimaryCareFlow };
      case FlowType.ClinicianFirst:
        return { loginRequired: false, flow: ClinicianFirstFlow };
      case FlowType.PCPBooking:
        return { loginRequired: true, flow: PCPBookingFlow };
      case FlowType.PCPChoice:
        return { loginRequired: true, flow: PCPChoiceFlow };
      case FlowType.Specialist:
        return { loginRequired: false, flow: SpecialistFlow };
      case FlowType.PreventativeAWV:
        return { loginRequired: false, flow: AnnualWellnessVisitFlow };
      default:
        return { loginRequired: false, flow: PrimaryCareFlow };
    }
  };
  // eslint-disable-next-line prefer-const
  let { loginRequired, flow } = loadFlow(flowType);

  const redirect = async (target: Step, additionalQuery?: ParsedUrlQueryInput | Event) => {
    let finalQuery = router.query as ParsedUrlQueryInput;
    if (additionalQuery) {
      // this prevents situation when we set this as event handler and there the Event is passed
      // example onClick={goToNext} was passing Event to the function
      // we could be fixing that as onClick={() => goToNext()}
      // but this fix allows for still using directly without introduction of additional anonymous functions
      const isNotEvent = !additionalQuery.target;
      if (isNotEvent) {
        // append for any preventative Flow
        if ([FlowType.PreventativeAWV].includes(flowType) || finalQuery.flow_type === FLOW_TYPES.PREVENTATIVE) {
          // for IFP user, 1st annual visit should be at least one week from date of registration
          const adjustedDate = adjustAppointmentDate(finalQuery, userData.employee, userData.userprofile.date_joined);
          finalQuery = { ...finalQuery, ...additionalQuery, ...adjustedDate } as ParsedUrlQueryInput;
        } else {
          // replace query
          finalQuery = additionalQuery as ParsedUrlQueryInput;
        }
      }
    }
    return router.push({
      pathname: target.url,
      query: finalQuery, // pass the current query
    });
  };

  const goToStart = (additionalQuery?: ParsedUrlQueryInput) => {
    return redirect(flow[0], additionalQuery);
  };

  const currentIndex = useMemo(
    () => {
      const currentUrl = router.pathname;
      // Remove trailing slashes and compare
      return flow.findIndex((st: any) => st && currentUrl.replace(/\/$/, '') === st.url.replace(/\/$/, ''));
    },
    [flow, router.pathname],
  );

  // Warning this does not has query params included!
  const startUrl = flow[0].url;

  const jump = (
    target: PossibleStep,
    additionalQuery?: ParsedUrlQueryInput,
  ) => redirect(allSteps[target], additionalQuery);

  const prefetch = (target: PossibleStep) => router.prefetch(allSteps[target].url);
  const prefetchNext = () => {
    const link = nextLink();
    if (link) {
      router.prefetch(link);
    }
  };

  // prefetching next page in the flow
  // there is possibility to turn off this in exception situation by setting prefetchNextPage: false
  useEffect(() => {
    if (prefetchNextPage) {
      prefetchNext();
    }
  }, []); // called once in every page using this pcBookingFlowHook

  useEffect(() => {
    if (!flowType) {
      return;
    }

    // Flows that require a login should goto login screen and restart
    if (!isLoggedUser && loginRequired) {
      router.push({
        pathname: '/login/',
        query: `next=${flow[0].url}`,
      }).then();
    }

    // When the flowtype is updated and we are not currently in a booking flow, goto the start
    const currIndex = flow.findIndex((st: any) => st && router.asPath.includes(st.url));
    if (currIndex === -1) {
      // fix me: in some cases this redirect doesn't work. For example: specialists flow ends and redirects to
      // dashboard. Another example: back button to /booking/visit-type/. Probably need formal end of flow.
      // router.push(flow[0].url);
    }
  }, [flowType]);

  const skipNextStep = (additionalQuery?: ParsedUrlQueryInput) => {
    const nextStep = flow[currentIndex + 2];
    if (!nextStep) {
      return Promise.resolve(false);
    }
    return redirect(nextStep, additionalQuery);
  };

  const goToNext = (additionalQuery?: ParsedUrlQueryInput) => {
    const nextStep = flow[currentIndex + 1];
    if (!nextStep) {
      return Promise.resolve(false);
    }
    return redirect(nextStep, additionalQuery);
  };

  const nextLink = () => {
    const nextStep = flow[currentIndex + 1];
    if (!nextStep) {
      return null;
    }
    return nextStep.url;
  };

  const goToPrev = (additionalQuery?: ParsedUrlQueryInput) => {
    const prevStep = flow[currentIndex - 1];
    if (!prevStep) {
      return Promise.resolve(false);
    }
    return redirect(prevStep, additionalQuery);
  };

  const getBookingFlowType = (): FlowType | null | undefined => flowType;

  const gotoBookingFlow = (flowTypeRequested: FlowType | null = null, flush: boolean = true) => {
    if (flush) flushBookingFlow(dispatch, router);
    if (flowTypeRequested) {
      dispatch(setFlowType(flowTypeRequested));
    } else {
      dispatch(setFlowType(FlowType.PrimaryCare));
    }

    flow = loadFlow(flowTypeRequested).flow;
    goToStart();
  };

  return {
    prefetch,
    jump,
    goToNext,
    nextLink,
    goToPrev,
    skipNextStep,
    goToStart,
    startUrl,
    currentIndex,
    length: flow.length,
    getBookingFlowType,
    gotoBookingFlow,
  };
};

export const useIsPCFlow = () => {
  const router = useRouter();
  return router.pathname.includes('booking/primary-care/');
};
