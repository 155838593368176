import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import styled from '@emotion/styled';
import PlushCareLogo from 'public/img/nextgen/PlushCareLogo.png';
import AccoladeCareLogo from 'public/img/nextgen/AccoladeCare.svg';
import BscLogo from 'public/img/nextgen/BSC.svg';
import PlushCareLogoSecondary from 'public/img/nextgen/PlushCareLogoSecondary.png';
import AccoladeLogo from 'public/img/accolade/accolade_logotype_nd_color.png';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import { useBrand } from 'src/js/nextgen/providers/BrandProvider';

const PlushCareLogoStyled = styled.a`
  z-index: 20;
  line-height: 1;
`;

const AccoladeLogoStyled = styled.div`
  position: relative;
  display: inline-block;
  padding-left: 1rem;

  ::before {
    content: '';
    width: 2px;
    height: calc(100% - 8px);
    background: ${props => props.theme.colors.foreground};
    position: absolute;
    top: 0.25rem;
    left: 0.5rem;
    right: 0;
    bottom: 0;
  }
`;

const BscLogoWrapper = styled.div`
  position: absolute;
  top: 5px;
`;

const PlushCareLogoSection = ({
  displayAltLogo, logoUrl, onLogoClick, enterprise,
}) => {
  const appVariant = useAppVariant();
  const isUserLoggedIn = useSelector(state => state.user && state.user.isLoggedIn);
  const [currentLogoUrl, setCurrentLogoUrl] = useState(logoUrl);

  useEffect(() => {
    if (isUserLoggedIn) {
      setCurrentLogoUrl('/myhealth/dashboard/');
    } else if (logoUrl) {
      setCurrentLogoUrl(logoUrl);
    } else {
      setCurrentLogoUrl('/');
    }
  }, [isUserLoggedIn, logoUrl]);
  /**
   * IMPORTANT - Why we have this state here if we could just use the prop
   * The problem is that enterprise prop is send here from redux
   * (yes this is info outside of this component responsibility)
   * But still what happens is for NextJs rendering this component always is rendered as not enterprise,
   * as Next has no knowledge in redux, but in browser it can be enterprise, in result NextJs renders different
   * output which does not match what browser wants to render, what ends by console error
   *
   * Previous solution to this was not rendering this component at all for nextjs
   * What ended by issues like moving button, as flex layout was adjusting differently elements
   *
   * The proposed solution makes first render the same for browser and server, so first render
   * always renders standard version without enterprise, then we make second render in the browser
   * which adds or not the additional enterprise image
   *
   * This solves rendering differences as first render is the same, also fixes style issues as element
   * is rendered for both server and browser
   */
  const [isEnterprise, setIsEnterprise] = useState(false);
  useEffect(() => {
    setIsEnterprise(enterprise);
  }, [enterprise]);

  const brand = useBrand();

  const displayAccoladeLogo = isEnterprise;
  const logos = appVariant.variant === AppVariants.AccoladeCare
    ? (
      brand.color_scheme === 'bsc'
        ? (
          <BscLogoWrapper>
            <Image
              src={BscLogo}
              alt={brand.application_title}
              width={90}
              height={40}
            />
          </BscLogoWrapper>
        )
        : (
          <Image
            src={AccoladeCareLogo}
            alt={brand.application_title}
            width={180}
            height={40}
          />
        )
    )
    : (
      <>
        <Image
          src={(!displayAltLogo) ? PlushCareLogo : PlushCareLogoSecondary}
          alt="Online Doctors Available Today from PlushCare, an Accolade company"
          width={116}
          height={19}
        />
        {displayAccoladeLogo && <AccoladeLogoStyled><Image src={AccoladeLogo} alt="Accolade" width={100} height={25} /></AccoladeLogoStyled>}
      </>
    );

  return (
    <PlushCareLogoStyled href={currentLogoUrl} id="header-logo-link" onClick={(e) => onLogoClick(e, currentLogoUrl)}>
      {logos}
    </PlushCareLogoStyled>
  );
};

export default PlushCareLogoSection;
