import React, { ReactNode, useEffect, useState } from 'react';
import { TimerStyled } from './Timer.styled';
import {
  addToDate, durationInMiliseconds,
  getTimeLeftFormatted, isDateAfter,
  isDateValid, timeToUtcLocal, todayInTimezone,
} from '../../../utils/datetime';

// startTime, currentTime format: '2021-05-15 11:18:09.331840+00:00'
export const calcBookingHoldLeftTime = (durationInMinutes: number, startTime: string, currentTime: string) => {
  const endTime = addToDate('minute', durationInMinutes, startTime);
  const timeLeft = durationInMiliseconds(currentTime, endTime);
  return isDateAfter(currentTime, endTime) ? 0 : timeLeft;
};

const isOver = (time: number | null) => time === 0;
const timerHasStarted = (time: number | null) => time !== null;

export const Timer = (props: {
  text: string,
  durationInMinutes: number,
  startTime: string,
  fallbackElement: ReactNode
}) => {
  const {
    text, durationInMinutes, startTime, fallbackElement,
  } = props;

  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    const updateTimeLeft = () => {
      setTimeLeft(calcBookingHoldLeftTime(durationInMinutes, timeToUtcLocal(startTime), todayInTimezone()));
    };
    const holdTimer = setInterval(updateTimeLeft, 1000);
    updateTimeLeft();
    return () => {
      clearInterval(holdTimer);
    };
  }, [durationInMinutes, startTime]);

  return timerHasStarted(timeLeft)
    ? (
      <TimerStyled>
        {isOver(timeLeft) ? (
          <>{fallbackElement}</>
        ) : isDateValid(timeLeft) && (
          <div className="timer-text">
            {text}
            <span className="timer-widget">
              {getTimeLeftFormatted(timeLeft as number)}
            </span>
          </div>
        )}
      </TimerStyled>
    ) : null;
};
