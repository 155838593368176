import styled from '@emotion/styled';

export const TimerStyled = styled.div`
  color: ${props => props.theme.colors.foreground};
  text-align: right;
  font-size: 14px;

  .timer-widget {
    font-feature-settings: "tnum";
    padding-left: 5px;
    font-weight: 600;
  }
`;
