import React from 'react';
import { colors } from '../nextgen/plushcare-components';

const getPath = (name, props) => {
  switch (name) {
    case 'camera':
      return (
        <path
          {...props}
          fillRule="evenodd"
          strokeLinejoin="round"
          d="M21.5 11.5c.55 0 1 .45 1 1v11c0 .55-.45 1-1 1h-13c-.55 0-1-.45-1-1v-11c0-.55.45-1 1-1h13zm9 1v11l-6-3v-5l6-3z"
        />
      );
    case 'cameraOff':
      return (
        <g
          {...props}
          fillRule="evenodd"
        >
          <path d="M21.5 11.5c.55 0 1 .45 1 1v11c0 .55-.45 1-1 1h-13c-.55 0-1-.45-1-1v-11c0-.55.45-1 1-1h13zm9 1v11l-6-3v-5l6-3z" strokeLinejoin="round" />
          <path strokeLinecap="square" d="M9.25 33.521L27.75 1.48" />
        </g>
      );
    case 'phone':
      return (
        <path
          {...props}
          d="M26.318 22.422h3.786a2.125 2.125 0 002.124-2.124l-.002-2.075c0-1.61-1.13-2.991-2.708-3.314a58.285 58.285 0 00-23.036-.001 3.385 3.385 0 00-2.71 3.314l.001 2.076a2.12 2.12 0 002.123 2.122H9.68a2.12 2.12 0 002.122-2.123l.001-1.624.36-.035c3.801-.378 7.867-.377 11.67.001l.361.036v1.623c0 1.172.951 2.123 2.123 2.124z"
          strokeWidth=".8"
          fillRule="evenodd"
        />
      );
    case 'mic':
      return (
        <g
          {...props}
          fillRule="evenodd"
        >
          <rect x="13.5" y="6.5" width="8" height="13" rx="4" />
          <path d="M10 14c0 4.97 3.358 9 7.5 9 4.142 0 7.5-4.03 7.5-9" strokeLinecap="round" strokeLinejoin="round" />
          <rect x="17.5" y="23.5" width="1" height="6" rx=".5" />
          <rect x="12.5" y="29.5" width="10" height="1" rx=".5" />
        </g>
      );
    case 'muted':
      return (
        <g
          {...props}
          fillRule="evenodd"
        >
          <rect x="13.5" y="6.5" width="8" height="13" rx="4" />
          <path d="M10 14c0 4.97 3.358 9 7.5 9 4.142 0 7.5-4.03 7.5-9" strokeLinecap="round" strokeLinejoin="round" />
          <rect x="17.5" y="23.5" width="1" height="6" rx=".5" />
          <rect x="12.5" y="29.5" width="10" height="1" rx=".5" />
          <path strokeLinecap="square" d="M8.25 33.521L26.75 1.48" />
        </g>
      );
    case 'chevron':
      return (
        <g
          {...props}
          id="Sign-in"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Dashboard-Menu" transform="translate(-1875.000000, -20.000000)" fill={props.fill}>
            <g id="Navigation" transform="translate(1805.000000, 10.000000)">
              <g id="User">
                <g id="icon-drop-down" transform="translate(70.000000, 10.000000)">
                  <polygon id="down-chevron" transform="translate(5.000000, 6.000000) scale(-1, 1) rotate(-90.000000) translate(-5.000000, -6.000000) " points="2 6 7 1 8 2 4 6 8 10 7 11" />
                </g>
              </g>
            </g>
          </g>
        </g>
      );
    case 'back':
      return (
        <g {...props} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="10.04.00---Assessment-Flagged" transform="translate(-6.000000, -162.000000)" stroke={colors.darkerBlue} strokeWidth="2">
            <g id="Back" transform="translate(6.000000, 162.000000)">
              <polyline id="Path-2" points="25.75 14.5 18.25 22 25.75 29.5" />
            </g>
          </g>
        </g>
      );
    case 'search':
      return (
        <g {...props} fill="none" fillRule="evenodd" strokeLinejoin="round">
          <g transform="translate(-207 -519)">
            <g transform="translate(207 327)">
              <g transform="translate(0 192)">
                <path d="m16.5 8.5c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8z" />
                <line x1="14.156" x2="23.5" y1="14.156" y2="23.5" strokeLinecap="round" />
              </g>
            </g>
          </g>
        </g>
      );
    case 'thumbsup':
      return (
        <svg
          width="36"
          height="36"
          viewBox="0 0 52 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.7204 38.5561C46.7204 40.565 45.1663 42.1909 43.2463 42.1909H40.9303C42.8503 42.1909 44.4044 43.8193 44.4044 45.8258C44.4044 47.8346 42.8503 49.4606 40.9303 49.4606H25.8762C16.6121 49.4606 18.9282 44.6141 0.400024 44.6141V22.8052H7.34808C15.4541 22.8052 24.7182 14.9976 24.7182 3.4195C24.7182 -0.411597 31.6663 -0.89624 31.6663 6.3734C31.6663 11.2198 29.3502 20.382 29.3502 20.382H47.8784C49.7984 20.382 51.3524 22.0104 51.3524 24.0168C51.3524 26.0257 49.7984 27.6516 47.8784 27.6516H45.5624C47.4823 27.6516 49.0364 29.28 49.0364 31.2865C49.0364 33.2953 47.4823 34.9213 45.5624 34.9213H43.2463C45.1663 34.9213 46.7204 36.5497 46.7204 38.5561Z"
            fill={colors.greywacke}
          />
        </svg>
      );
    case 'thumbsdown':
      return (
        <svg
          width="36"
          height="36"
          viewBox="0 0 53 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99128 10.9266C4.99128 8.91771 6.57641 7.29173 8.53479 7.29173H10.8971C8.93875 7.29173 7.35362 5.66333 7.35362 3.65691C7.35362 1.64806 8.93875 0.0220871 10.8971 0.0220871H26.2523C35.7017 0.0220871 33.3393 4.86852 52.238 4.86852V26.6774H45.151C36.8828 26.6774 27.4335 34.485 27.4335 46.0632C27.4335 49.8943 20.3465 50.3789 20.3465 43.1093C20.3465 38.2628 22.7088 29.1007 22.7088 29.1007H3.81012C1.85174 29.1007 0.266609 27.4723 0.266609 25.4658C0.266609 23.457 1.85174 21.831 3.81012 21.831H6.17245C4.21408 21.831 2.62894 20.2026 2.62894 18.1962C2.62894 16.1874 4.21408 14.5614 6.17245 14.5614H8.53479C6.57641 14.5614 4.99128 12.933 4.99128 10.9266Z"
            fill={colors.greywacke}
          />
        </svg>
      );
    case 'star':
      return (
        <svg
          width="36"
          height="36"
          viewBox="0 0 46 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.9988 2.26986L29.2117 14.9243C29.3569 15.2201 29.6386 15.4254 29.9647 15.473L43.8601 17.5038L33.8022 27.3666C33.5682 27.596 33.4615 27.9255 33.5165 28.2485L35.8874 42.1674L23.4659 35.5995C23.1734 35.4449 22.8233 35.4449 22.5309 35.5996L10.1157 42.1669L12.4866 28.2485C12.5416 27.9254 12.4348 27.5959 12.2008 27.3665L2.14033 17.5038L16.0384 15.473C16.3646 15.4254 16.6464 15.22 16.7916 14.9239L22.9988 2.26986Z"
            stroke={colors.americanYellow}
            strokeWidth="2"
            strokeLinejoin="round"
            fill={props.fill}
          />
        </svg>
      );
    case 'messageBubble':
      return (
        <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1335 18.9035C14.1335 20.5107 15.4266 21.8129 17.0224 21.8129H19.9113L23.378 25.3042V21.8129H25.1113C26.7071 21.8129 28.0002 20.5107 28.0002 18.9035V16.576C28.0002 14.9689 26.7071 13.6666 25.1113 13.6666" stroke="#283779" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M17.5999 0.865356H6.04433C3.49172 0.865356 1.42212 2.94965 1.42212 5.52038V10.1754C1.42212 12.7473 3.49172 14.8304 6.04433 14.8304H7.19989V19.4855L12.3999 14.8304H17.5999C20.1525 14.8304 22.2221 12.7473 22.2221 10.1754V5.52038C22.2221 2.94965 20.1525 0.865356 17.5999 0.865356V0.865356Z" stroke="#283779" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    default:
      return <path />;
  }
};

function SVGIcon(props) {
  const {
    name, svgDimensions, stroke, fill,
  } = props;
  return (
    <svg
      width={svgDimensions || '36px'}
      height={svgDimensions || '36px'}
    >
      {getPath(name, { stroke, fill })}
    </svg>
  );
}

export default SVGIcon;
