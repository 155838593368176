import styled from '@emotion/styled';
import { colors } from '../../plushcare-components';
import Banner from './Banner/Banner';
import { headerSizes } from '../../plushcare-components/helpers/plushcare-style-defaults';

export const NavStyled = styled.nav`
  position: relative;
  z-index: 5;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${headerSizes.mobile.height}px;
  padding: 0 20px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  font-family: 'TT Commons';
  transition: ease all 0.5s;

  @media (max-width: 767.98px) {
    flex-wrap: wrap;
    height: auto;
    min-height: ${headerSizes.mobile.height}px;
    padding: 11px 16px;
  }
`;

export const NavElement = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: auto 0;

  li a {
    text-decoration: none;
  }

  button a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
  }

  .nav-item a {
    color: ${props => props.theme.colors.foreground};
  }
  .nav-item svg {
    margin-left: 8px;
  }
`;

export const DesktopNavigation = styled(NavElement)`
  display: none;

  li {
    position: relative;
    display: flex;
    align-items: center;
  }

  li + li {
    margin-left: 32px;
  }

  .plushcare-button {
    svg {
      margin: -2px 0 0 8px;
    }
    #Dashboard-Menu {
      fill: currentColor;
    }
  }

  .plushcare-dropdown-menu {
    list-style: none;
    position: absolute;
    top: 31px;
    right: 0;
    z-index: 2;
    width: 135px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    margin-top: 10px;
    background: ${colors.white};

    li {
      margin: 0;
      color: ${props => props.theme.colors.foreground};
      white-space: nowrap;
    }

    a {
      color: ${props => props.theme.colors.foreground};
      cursor: pointer;

      &:hover {
        font-weight: 700;
      }
    }
  }

  @media (min-width: 600px) {
    display: flex;
  }
`;

export const BannerContainer = styled.div`
  .no-height {
    height: 0;
    overflow: hidden;
  }

`;

export const HealthlineBanner = styled(Banner)`
  background: ${colors.cloud};
  height: 38px;
  transition: height 0.5s ease-in;
  z-index: 2;

  p {
    color: ${props => props.theme.colors.foreground};
    display: flex;
    align-items: end;
    justify-content: center;
  }

  img {
    height: 13px;
  }

  @media (max-width: 404px) {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`;

export const MobileNavigation = styled(NavElement)`
  @media (min-width: 600px) {
    display: none;
  }
`;

export const MobileMenuStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  padding: 120px 25px 100px;
  background-color: ${props => props.theme.colors.primaryBackground};
  transition: all 0.5s;
  color: ${props => props.theme.colors.foreground};
  text-align: left;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
    font-size: 30px;

    a {
      color: ${props => props.theme.colors.foreground};
    }
  }
`;
