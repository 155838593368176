import styled from '@emotion/styled';

import { colors } from '../../../plushcare-components/helpers/plushcare-style-defaults';

export const BannerStyled = styled.div`
  width: 100%;
  background: ${colors.sunshine};
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  p {
    padding: 10px 20px;
    margin: 0;
  }

  a {
    color: ${props => props.theme.colors.foreground};
    text-decoration: underline;
  }
`;
