import { Router } from 'next/router';
import { appUrl } from 'src/js/utils';
import { getNextRoute } from 'src/js/utils/location';
import { IS_ACCOLADE_X } from 'utils/hooks/useAppVariant';

function removeLastSlashFromUrl(url: string) {
  const match = url.match(/(.+)\/$/);
  if (match) {
    return match[1]; // return url without last /
  }
  return url;
}

function getPathFromUrl(url: string) {
  return url.split('?')[0];
}

export function urlPath(url: string) {
  return removeLastSlashFromUrl(getPathFromUrl(url));
}

export function redirectTo(url: string) {
  return { redirect: { destination: url, permanent: false } };
}

// type guard for comfortable work with providers which may return redirects (which use 'serverRedirectToLogin' inside)
export const isRedirectCase = (p: any): p is withRedirectToLoginProps => !!(p as withRedirectToLoginProps).redirect;

export type withTherapyRedirectProps = ReturnType<typeof redirectToTherapyAppsList>
export function redirectToTherapyAppsList() {
  return redirectTo(appUrl.booking.therapy.list);
}

export type withRedirectToLoginProps = ReturnType<typeof serverRedirectToLogin>
export function serverRedirectToLogin(url?: string) {
  return redirectTo(url ? `/login?next=${encodeURIComponent(url)}` : appUrl.login);
}

export const serverRedirectToMain = () => redirectTo('/');

export function serverRedirectToNext(query: Router['query']) {
  const nextRoute = getNextRoute(query);
  return redirectTo(nextRoute || (IS_ACCOLADE_X ? appUrl.accoladeX.dashboard : appUrl.dashboard));
}

export type withRedirectToDashboardProps = ReturnType<typeof serverRedirectToDashboard>
export function serverRedirectToDashboard() {
  return redirectTo(appUrl.dashboard);
}
