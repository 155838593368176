import {
  SET_BOOKING_DOCTOR, SET_PRE_BOOKED_APPOINTMENT_DATA, SET_BOOKING_CLINICIAN,
  SET_BOOKING_FILTER_DATA, SET_INITIAL_BOOKING_DATA_LOADED, SET_FLOW_TYPE,
} from 'src/js/actionTypes/BookingActionType';
import { State } from 'src/js/reducers/BookingReducer';
import { LocalStorage } from 'src/js/utils';
import { UnavailableAppointment } from 'src/js/globalTypes';
import CookieStorage from 'src/js/utils/CookieStorage';
import { AvailableDoctor } from 'src/js/endpoints/appointments';
import { GetParams } from 'components/booking/ClinicianFirst/Search/searchTypes';
import { FlowType } from 'src/js/components/bookingFlow/pcBookingFlowHook';

export function setInitialBookingDataLoaded(initial_booking_data_loaded: boolean) {
  return {
    type: SET_INITIAL_BOOKING_DATA_LOADED,
    payload: initial_booking_data_loaded,
  };
}

export function setFlowType(flowType: FlowType | null) {
  return {
    type: SET_FLOW_TYPE,
    payload: flowType,
  };
}

export function setBookingDoctor(doctor_id: number) {
  return {
    type: SET_BOOKING_DOCTOR,
    payload: doctor_id,
  };
}

export function setBookingClinician(clinician: AvailableDoctor | null) {
  return {
    type: SET_BOOKING_CLINICIAN,
    payload: clinician,
  };
}

export function setFilterData(filterData: GetParams | null) {
  return {
    type: SET_BOOKING_FILTER_DATA,
    payload: filterData,
  };
}

// populates Booking store with data of appointment which is in "hold" state during booking flow
export function setPreBookedAppointment(appointmentData: State['preBookedAppointment']) {
  return {
    type: SET_PRE_BOOKED_APPOINTMENT_DATA,
    payload: appointmentData,
  };
}

// clears Booking store with data of appointment which is in "hold" state during booking flow
export function clearPreBookedAppointment() {
  return {
    type: SET_PRE_BOOKED_APPOINTMENT_DATA,
    payload: null,
  };
}

// used only by Therapy booking flow. Requires re-naming
export const preBookedAptCRUD = {
  namespace: 'prebooked-apt',
  load(): State['preBookedAppointment'] {
    return LocalStorage.getItem(preBookedAptCRUD.namespace) ?? null;
  },
  save(apt: State['preBookedAppointment']) {
    LocalStorage.setItem(preBookedAptCRUD.namespace, apt);
    // used by Nextjs to pre-render Therapy booking pages. Passed in "info" overrides existing data
    CookieStorage.set(preBookedAptCRUD.namespace, {
      selectedRecurringPlan: apt?.selectedRecurringPlan,
      rescheduleType: apt?.rescheduleType,
    }, null);
  },
  clear() {
    LocalStorage.removeItem(preBookedAptCRUD.namespace);
    CookieStorage.remove(preBookedAptCRUD.namespace);
  },
};

export const preventativeAptFlow = {
  clear() {
    LocalStorage.removeItem('flow_type');
    LocalStorage.removeItem('flow_sub_type');
  },
};

export const unavailableAppointmentsCRUD = {
  load(): UnavailableAppointment | undefined {
    return LocalStorage.getItem('unavailable-apt');
  },
  save(apt: UnavailableAppointment) {
    LocalStorage.setItem('unavailable-apt', apt);
  },
  clear() {
    LocalStorage.removeItem('unavailable-apt');
  },
};

// used to pass required info about appointment which is being rescheduled to Nextjs via cookies.
// passed in "fieldsToSave" overrides existing data
export const rescheduleAptCRUD = {
  namespace: 'reschedule-apt',
  save(fieldsToSave: Partial<State['preBookedAppointment']>) {
    CookieStorage.set(rescheduleAptCRUD.namespace, fieldsToSave, null);
  },
  clear() {
    CookieStorage.remove(rescheduleAptCRUD.namespace);
    LocalStorage.removeItem('reschedule_reason');
  },
};

export enum SELECTED_APT_KIND_OPTIONS {
  paidApt = 'paidApt',
  freeApt = 'freeApt'
}

// namespace separated out because it's used in another type definition
export const selectedAptKindNamespace = 'selectedAptKind';
export const selectedTherapyKindCRUD = {
  namespace: selectedAptKindNamespace,
  save(selectedOption: SELECTED_APT_KIND_OPTIONS) {
    CookieStorage.set(selectedTherapyKindCRUD.namespace, selectedOption, null);
  },
  load(): SELECTED_APT_KIND_OPTIONS {
    return CookieStorage.get(selectedTherapyKindCRUD.namespace);
  },
  clear() {
    CookieStorage.remove(selectedTherapyKindCRUD.namespace);
  },
};

type PageErrProps = {
  message: string
  // clears message on `get`. This may be useful if we don't want to bother with clearing error manually
  clearOnGet?: boolean
}
// allows to set and get error message between pages which in addition can be auto removed
export const pageErrorCRUD = {
  namespace: 'page-error',
  get() {
    const err: PageErrProps | undefined = LocalStorage.getItem(pageErrorCRUD.namespace);

    if (!err) return null;

    if (err.clearOnGet) pageErrorCRUD.remove();

    return err.message;
  },
  set(props: PageErrProps) {
    LocalStorage.setItem(pageErrorCRUD.namespace, props);
  },
  remove() {
    LocalStorage.removeItem(pageErrorCRUD.namespace);
  },
};
