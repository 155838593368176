import { IncomingMessage } from 'http';
import requestIp from 'request-ip';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import useSWR from 'swr';
import {
  DoctorType, ANONYMIZED_DOCTOR_TYPE_MAP, DEANONYMIZED_DOCTOR_TYPE_MAP, DOCTOR_TYPE, AnonymizedDoctorType,
} from 'src/js/nextgen/utils/constants';
import { apiUrl, PlushcareWebAPI, LocalStorage } from 'src/js/utils';
import {
  get, isSuccess, post, ServerGetParams, V2Error, error, noResponseError, success, V2Response, V2Success,
} from 'src/js/utils/apiV2';
import {
  Appointment, UnavailableAppointment, UpcomingAppointment,
} from 'src/js/globalTypes';
import { UUID } from 'src/js/endpoints/types';
import { unavailableAppointmentsCRUD } from 'src/js/actions/BookingActions';
import { StateAbbreviation } from 'utils/USStateMapping';
import { isServer } from 'src/js/utils/server';
import { FilterParams } from 'utils/hooks/appointmentHooks';
import { PaymentMethod } from 'src/js/store/payment';
import { PreBookedAppointment } from 'src/js/reducers/BookingReducer';
import { getFetcher } from 'src/js/endpoints/fetchers';
import Analytics, { ERROR_TRIGGER } from 'src/js/utils/analytics/Analytics';

export enum HeldStatus {
  HELD = 'held',
  HELD_BY_ANOTHER_USER = 'held_by_another_user',
  NOT_HELD = 'not_held',
  UNKNOWN = 'unknown'
}

export enum BookingStatus {
  BOOKED = 'Booked',
  AVAILABLE = 'Available'
}

export type HeldKnownResponse = {
  held_status: HeldStatus.HELD | HeldStatus.HELD_BY_ANOTHER_USER | HeldStatus.NOT_HELD,
  booking_status: BookingStatus
}
export type HeldUnknownResponse = {
  held_status: HeldStatus.UNKNOWN,
  booking_status: null
}
export type HeldAppointmentStatusResponse = HeldKnownResponse | HeldUnknownResponse;
export const heldAppointmentStatus = () => getFetcher('/v2/patient/appointments/held-appointment-status');

export type DoctorSpecialty = {
  specialty: string,
  patient_age_category?: string,
}

export type AvailableDoctor = {
  doctor_id: number
  bio: string
  image_url: string | null
  first_name: string
  last_name: string
  years_practiced: number
  rating: string
  primary_doctor_type: DoctorType,
  residency_program: string,
  available: {
    weekdays: boolean,
    weekends: boolean
  },
  average: {
    rolling_average: string,
    last_updated: string
  },
  npi: string,
  pcp_eligible: boolean,
  languages: string[],
  is_exact_match?: boolean,
  race?: string,
  gender?: string,
  care_types?: string[],
  doctor_specialties?: DoctorSpecialty[],
  designation?: string,
  prefix?: string,
  total_reviews?: number
}

export type AvailableAppointment = {
  id: number // 4698
  appointment_id: string // "a5fd3656-dfa7-4724-9be1-0fea672309e1"
  appointment_utc_time: string // "2022-02-08T07:45:00Z"
  doctor_id: number
  hold_length: number // 10
  is_nocturnal: null | boolean
  unavailable_dates: null | string[]
}

// this is what server sends
// sometimes we get nulls
export type AvailableAppointmentsResponseParams = {
  timezone_id: string,
  state: StateAbbreviation,
  doctor_type: DoctorType,
  out_of_network: boolean,
  date: string,
  pagination_size: number,
  payer_id: string | null,
  paginate_cursor_appt: number | null,
  free_consults: boolean | null,
  doctor_id: number | null,
  language: string | null,
}
// this is what we send to the server
export type AvailableAppointmentsQueryParams = {
  state?: StateAbbreviation,
  doctor_type: DoctorType,
  out_of_network?: boolean,
  payer_id?: string,
  paginate_cursor_appt?: number,
  free_consults?: boolean,
  date?: string,
  pagination_size?: number,
  doctor_id?: number,
  trigger?: Trigger,
  flow_sub_type?: string | null,
  language?: string,
}

export type Trigger = 'initial' | 'changed_state' | 'changed_date' | 'changed_in_network_status' | 'changed_doctor_type' | 'changed_doctor' | 'loaded_more_appts' | 'changed_multiple_filters' | 'changed_time_of_day' | 'changed_language';

// it removes not used in query fields like timezone_id and replaces nulls as undefined
export function applyQueryChange(
  initialQueryParams: AvailableAppointmentsQueryParams,
  serverParams: AvailableAppointmentsResponseParams,
): AvailableAppointmentsQueryParams {
  const query = {
    ...initialQueryParams,
    // this assumes only these three can be changed by server
    date: serverParams.date,
    state: serverParams.state,
    out_of_network: serverParams.out_of_network,
    doctor_type: serverParams.doctor_type,
  };
  return query;
}

// the original server response
export type AvailableAppointmentsOriginalResponse = {
  appointments: AvailableAppointment[],
  doctors: AvailableDoctor[],
  params: AvailableAppointmentsResponseParams,
}
// the one we want to use, as server sends more in params
// and sends nulls which we don't wanna send back
export type AvailableAppointmentsResponse = {
  appointments: AvailableAppointment[],
  doctors: AvailableDoctor[],
  queryParams: AvailableAppointmentsQueryParams,
  queryParamsClientSent: AvailableAppointmentsQueryParams,
}

export const fetchAppointments = async (
  queryParams: AvailableAppointmentsQueryParams,
  req?: IncomingMessage & { cookies: NextApiRequestCookies; },
): Promise<AvailableAppointmentsResponse> => {
  if (!isServer) {
    // Browser
    type Response = { data: { payload: AvailableAppointmentsOriginalResponse } };
    const { data: { payload } }: Response = await PlushcareWebAPI.apiGet(
      apiUrl.getAppointments,
      deAnonymizePayloadDoctorType(queryParams),
    );

    return {
      appointments: payload.appointments,
      doctors: payload.doctors,
      queryParams: applyQueryChange(queryParams, payload.params), // what server really used
      queryParamsClientSent: queryParams, // what we did send to the server
    };
  }
  // Server
  if (!req) {
    throw Error('In server req is mandatory param');
  }
  const userAgent = req.headers['user-agent'];
  const { cookie } = req.headers;
  const { cookies } = req;
  const clientIP = requestIp.getClientIp(req);
  const { data: { payload } } = await PlushcareWebAPI.staticServerGet({
    url: apiUrl.getAppointments,
    cookie,
    token: cookies.token,
    params: deAnonymizePayloadDoctorType(queryParams),
    customHeaders: {
      timezone_offset: cookies?.timezone_offset,
      userAgent,
      clientIP,
    },
  });

  return {
    appointments: payload.appointments,
    doctors: payload.doctors,
    queryParams: applyQueryChange(queryParams, payload.params), // what server really used
    queryParamsClientSent: queryParams, // what we did send to the server
  };
};

export type AvailableDatesQueryParams = {
  state: StateAbbreviation,
  doctor_type: DoctorType,
  out_of_network?: boolean,
  payer_id?: string,
  free_consults?: boolean,
  doctor_id?: number,
  flow_sub_type?: string | null
}
export const fetchDates = async (
  params: AvailableDatesQueryParams,
  req?: IncomingMessage & { cookies: NextApiRequestCookies; },
): Promise<string[]> => {
  if (!isServer) {
    const { data } = await PlushcareWebAPI.apiGet(apiUrl.getAppointmentDates, params);
    return data?.payload?.dates ?? [];
  }
  if (req) {
    const userAgent = req.headers['user-agent'];
    const { cookie } = req.headers;
    const { cookies } = req;
    const clientIP = requestIp.getClientIp(req);
    const { data } = await PlushcareWebAPI.staticServerGet({
      url: apiUrl.getAppointments,
      cookie,
      token: cookies.token,
      params,
      customHeaders: {
        timezone_offset: cookies?.timezone_offset,
        userAgent,
        clientIP,
      },
    });
    return data?.payload?.dates ?? [];
  }

  return [];
};

// V1 End points
type HoldAppointmentReqPayload = {
  appointment_id: number,
  doctor_type: string,
  previous_appointment_id: UUID | boolean | string[] | undefined
}

type CancelRecurringPlanType = {
  reason: string,
  end_datetime?: string,
};

export const getAppointmentData = (
  appointment: string,
): Promise<{ data: { id: number } }> => PlushcareWebAPI.apiGet(`${apiUrl.patients.appointment}${appointment}`);

export const sendHoldAppointmentRequest = (
  payload: HoldAppointmentReqPayload,
): Promise<{ data: Appointment }> => PlushcareWebAPI.apiPost(
  apiUrl.patients.appointmentHold,
  payload,
);

export const getAvailableAppointments = (params: {
  state: string
  doctor_type: string
  start_time: string
  out_of_network: boolean
  payer_id: string
  pagination_size: number
  paginate_cursor_appt: number
  doctor_id?: string
}): Promise<{
  data: {
    appointments: any[],
    doctors: any[],
    state: any,
  }
}> => PlushcareWebAPI.apiGet('/patients/appointments/available/new/', params);

export const getAvailableAppointmentsDates = (state: { value: string }, doctorType: DoctorType, params: {
  tz: string,
  out_of_network: string,
  payer_id: string
  doctor?: UpcomingAppointment['doctor']['doctor_id']
}): Promise<{ data: { dates: string[] } }> => PlushcareWebAPI.apiGet(`/patients/appointments/available/dates/${state.value}/${doctorType}/`, params);

export const postCancelAppointment = (
  reason: string | null,
  previous_appointment_id?: number,
  notes?: string | null,
): Promise<{
  data: {
    segment_event_name: string,
    doctor_last_name: string,
    doctor_first_name: string,
    appointment_utc_time: string,
    timezone_identifier: string,
    message: string,
  }
}> => PlushcareWebAPI.apiPost(
  '/ver1/booking/cancel/',
  {
    reason,
    previous_appointment_id,
    cancelled_by: 'User',
    cancelled_device: 'Web',
    notes,
  },
  true,
);

export const cancelRecurringAppointments = (data: CancelRecurringPlanType):
  Promise<{ data: V2Response<{ result: boolean }> }> => PlushcareWebAPI.apiPost(
  apiUrl.patients.cancelRecurringPlan,
  data,
);

export const useCancellationReasons = (isTherapy: boolean) => {
  return useSWR<V2Success<{
    cancellation_reasons: Array<{ key: string, value: string }>
  }>>(`${apiUrl.patients.getCancellationReasosn}?appointment_type=${isTherapy ? 'therapy' : ''}`, get);
};

export const postDiscardUnavailableAppointmentNotification = (unavailable_uuid: UUID) => {
  unavailableAppointmentsCRUD.clear();
  return post<{ unavailable_uuid: UUID }, UnavailableAppointment[], V2Error>('/v2/patient/appointments/unavailable/dismiss', { unavailable_uuid });
};

// used by getServerSideProps hook

export const fetchHeldAppointmentFromServer = async ({
  held_appointment, headerCookies, cookies,
}: { held_appointment: UUID } & ServerGetParams)
  : Promise<{ data: PreBookedAppointment, error: unknown }> => PlushcareWebAPI.staticServerGet({
  url: `${apiUrl.patients.appointment}${held_appointment}`,
  cookie: headerCookies,
  token: cookies?.token,
  customHeaders: { timezone_offset: cookies?.timezone_offset },
});

export type PriceMethod = 'with_insurance' | 'out_of_pocket';

type AppointmentPriceResponse = {
  appointment_price: string,
  used_credits: string,
  penalty: string,
  price_to_pay: string,
  default_price: string,
  extra_fees: ExtraFees | null,
  price_notes: string[] | null,
  out_of_network: boolean | null,
}

type ExtraFees = {
  after_hours?: AfterHoursFees
}

type AfterHoursFees = {
  base_price: string,
  credits_used_towards: string,
  payable: string
}
type AppointmentPriceError = {
  error: string,
  message: string,
}

export const getAppointmentPrice = (args: {
  payment_method: PriceMethod,
  appointment_id: UUID,
  reschedule_appointment_id?: UUID
  flow_type?: string,
  flow_sub_type?: string
}) => {
  return get<AppointmentPriceResponse, AppointmentPriceError>(apiUrl.getAppointmentPrice, { ...args });
};

export type NextAvailableDoctor = Pick<AvailableDoctor, 'prefix' | 'doctor_id' | 'first_name' | 'image_url' | 'last_name'> & { state: string, next_availability_date_time_utc: string, in_network: boolean };

export const anonymizeDoctorType = <T extends DoctorType | string | number>(doctorType: T): AnonymizedDoctorType => {
  if (typeof doctorType === 'undefined') {
    return DEANONYMIZED_DOCTOR_TYPE_MAP[DOCTOR_TYPE.INTERNAL];
  }
  // Number('0') -> 0, Number('internal') -> NaN
  if (Number.isNaN(Number(doctorType)) && Object.keys(DEANONYMIZED_DOCTOR_TYPE_MAP).includes(doctorType.toString())) {
    return DEANONYMIZED_DOCTOR_TYPE_MAP?.[doctorType as DoctorType]
      || DEANONYMIZED_DOCTOR_TYPE_MAP[DOCTOR_TYPE.INTERNAL];
  }
  if (Object.keys(ANONYMIZED_DOCTOR_TYPE_MAP).includes(doctorType.toString())) {
    return doctorType.toString() as AnonymizedDoctorType;
  }
  return DEANONYMIZED_DOCTOR_TYPE_MAP[DOCTOR_TYPE.INTERNAL];
};

export const deAnonymizeDoctorType = (doctorType: AnonymizedDoctorType | string | number): DoctorType => {
  if (typeof doctorType === 'undefined') {
    return DOCTOR_TYPE.INTERNAL;
  }
  // Number('0') -> 0, Number('internal') -> NaN
  if (Number.isNaN(Number(doctorType)) && Object.keys(DEANONYMIZED_DOCTOR_TYPE_MAP).includes(doctorType.toString())) {
    return doctorType as DoctorType;
  }
  if (Object.keys(ANONYMIZED_DOCTOR_TYPE_MAP).includes(doctorType.toString())) {
    return ANONYMIZED_DOCTOR_TYPE_MAP?.[doctorType.toString() as AnonymizedDoctorType];
  }
  return DOCTOR_TYPE.INTERNAL;
};

export const anonymizePayloadDoctorType = <T extends { doctor_type: DoctorType | string | number }>(data: T): Omit<T, 'doctor_type'> & { doctor_type: AnonymizedDoctorType } => {
  const doctorType = anonymizeDoctorType(data.doctor_type);
  return { ...data, doctor_type: doctorType };
};

export const deAnonymizePayloadDoctorType = <T extends { doctor_type: string | number }>(data: T): Omit<T, 'doctor_type'> & { doctor_type: DoctorType } => {
  const doctorType = deAnonymizeDoctorType(data.doctor_type);
  return {
    ...data,
    doctor_type: doctorType,
  };
};

export const getDoctors = async ({ filters, url }: { filters: Pick<FilterParams, 'doctor_type' | 'state' | 'payer_id' | 'language'>, url: string }): Promise<NextAvailableDoctor[]> => {
  const {
    doctor_type, state, payer_id, flow_sub_type, language,
  } = filters;
  const response = await get<NextAvailableDoctor[], V2Error>(
    url,
    deAnonymizePayloadDoctorType({
      doctor_type,
      payer_id,
      state,
      flow_sub_type,
      language,
    }),
  );
  return isSuccess(response) ? response.payload : [];
};

export type SpecialtyCareRequest = {
  doctor_id: number,
  request_time: string,
  request_message: string,
  terms_agreement?: boolean,
  is_minor: boolean,
}

export const sendSpecialtyCareRequest = (payload: SpecialtyCareRequest) => {
  return PlushcareWebAPI.post(apiUrl.specialist.requestAppointment, payload, true, true);
};

export type ReleaseCoverageAsyncApptPayload = {
  appointment_id: string,
  payment_method: PaymentMethod,
}

export const releaseHoldAsyncCoverageAppt = (payload: ReleaseCoverageAsyncApptPayload) => {
  return PlushcareWebAPI.get(apiUrl.appointments.asyncCoverageRelease, payload, true);
};

type SegmentContext = {
  email: string,
  phone: string,
  firstName: string,
  lastName: string,
  birthday: string,
  address: {
    state: string,
  }
}

export type BookPrimaryResponse = {
  appointment_id: number,
  appointment_price: number,
  appointments_with_doctor: number,
  booked_state: StateAbbreviation,
  coverage: string,
  coverage_group_id: string,
  coverage_used: boolean,
  enrolled_patient_to_subscription: boolean,
  new_or_repeat: 'New' | 'Repeat Patient',
  patient_group_id: string,
  payer_id: string,
  reservation_uuid: string,
  segment_event_name: string,
  subscription_program_id: string,
  system_assigned_pcp: boolean
  segment_event_secondary_name?: string
  segment_hashed_context: SegmentContext,
  reschedule_id: string | null,
  is_enterprise: boolean,
}

export type BookTherapyResponse = BookPrimaryResponse & { has_conflicts: boolean }

export type BookPrimaryPayload = {
  appointment_id: number,
  payment_method: PaymentMethod,
  state_abbreviation: StateAbbreviation,
  symptoms: string,
  doctor_type: Omit<'therapist', DoctorType>,
  appointment_category?: string,
  offer_uuid?: string,
  subscribe_user?: boolean,
  reschedule_reason?: string,
  stripe_token?: string,
  advertising_id?: string,
  appsflyer_id?: string,
  device_id?: string,
  previous_appointment_id?: number,
  child_id?: number,
  booked_by?: string,
  flow_type?: string,
  flow_sub_type?: string
  payment_method_id?: string
  save_payment_method_as_default?: boolean
}

export const bookPrimary = async (payload: BookPrimaryPayload): Promise<V2Response<BookPrimaryResponse>> => {
  try {
    const isPreventative = (payload.flow_type?.toLowerCase() || LocalStorage.getItem('flow_type')) === 'preventative';
    const url = isPreventative ? apiUrl.booking.bookPreventative : apiUrl.booking.bookPrimary;
    const { data } = await PlushcareWebAPI.apiPost(url, {
      ...payload,
      symptoms: isPreventative ? `Annual Preventative Check-up: ${payload.symptoms}` : payload.symptoms,
    }, true);
    return success(data);
  } catch (e: Error | any) {
    // Report raw error to analytics
    Analytics.paymentPCException(e, ERROR_TRIGGER.ON_CLICK, 'book_primary');
    if (e?.response?.status) {
      return error({
        code: e.response.status,
        code_alt: '',
        message: e.response?.data || '',
        error_data: e.response,
      });
    }
    return noResponseError;
  }
};
