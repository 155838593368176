import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../plushcare-components';

const ImpersonatorSectionStyled = styled.div`
  background: ${colors.trueRed};
  color: ${colors.white};
  text-align: center;
`;

const ImpersonatorSection = ({ impersonator }) => (
  <>
    { impersonator
        && (
          <ImpersonatorSectionStyled className="impersonator">
            {`You are impersonating the user ${impersonator}.`}
          </ImpersonatorSectionStyled>
        )}
  </>
);
export default ImpersonatorSection;
