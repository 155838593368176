import styled from '@emotion/styled';
import React from 'react';
import { useDefaultSupportNumber } from 'utils/hooks/useAppVariant';
import { useBrand } from '../../providers/BrandProvider';

const StyledPhoneNumber = styled.a``;

export const PhoneNumber = () => {
  const DEFAULT_PHONE_NUMBER = useDefaultSupportNumber();
  const { support_phone = DEFAULT_PHONE_NUMBER } = useBrand();
  const href = support_phone.replace(/[()\- ]/g, '');
  return (
    <StyledPhoneNumber className="support-phone-number" href={`tel:${href}`}>
      {support_phone}
    </StyledPhoneNumber>
  );
};
